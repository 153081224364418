import { openDB } from 'idb';

const dbPromise = openDB('my-pwa-db', 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('offline-data')) {
      db.createObjectStore('offline-data', { keyPath: 'id', autoIncrement: true });
    }
  },
});

export const saveData = async (data) => {
  const db = await dbPromise;
  return db.put('offline-data', data);
};

export const getAllData = async () => {
  const db = await dbPromise;
  return db.getAll('offline-data');
};

export const deleteData = async (id) => {
  const db = await dbPromise;
  return db.delete('offline-data', id);
};
