import React, { useState, useEffect } from 'react';
import { saveData, getAllData, deleteData } from './db';

function App() {
  const [online, setOnline] = useState(navigator.onLine);
  const [formData, setFormData] = useState('');
  const [nbLocalData, setNbLocalData] = useState(0);

  useEffect(() => {
    const handleOnline = async () => {
      setOnline(true);
      const unsyncedData = await getAllData();

      if (unsyncedData.length > 0) {
        // Simuler l'envoi des données au serveur
        unsyncedData.forEach(async (data) => {
          await sendToServer(data);  // Vous devrez implémenter sendToServer
          await deleteData(data.id);
        });
      }
    };

    const handleOffline = () => setOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = { content: formData, timestamp: Date.now() };

        if (online) {
            await sendToServer(data); // Envoyer immédiatement si en ligne
        } else {
            setNbLocalData(currVal => currVal+1)
            await saveData(data); // Sinon, sauvegarder localement
        }

        setFormData('');
    };

  return (
    <div>
      <h1>Mon Application PWA V4</h1>
      {nbLocalData>0 &&
        <p>{nbLocalData} données sauvegardées localement, en attente de connexion</p>
      }
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={formData}
          onChange={(e) => setFormData(e.target.value)}
        />
        <button type="submit">Sauvegarder</button>
      </form>
      <p>Status: {online ? 'En ligne' : 'Hors ligne'}</p>
    </div>
  );
}

async function sendToServer(data) {
  // Implémentez la logique pour envoyer les données au serveur
  console.log("Envoyer les données au serveurffee:", data);
  // Exemple: await fetch('https://example.com/api', { method: 'POST', body: JSON.stringify(data) });
  await fetch('https://test.digioptiga.fr/receptionPOST.php', { method: 'POST', body: JSON.stringify(data) })
  .then(res=> console.log(res.text()))
}

export default App;
